const myAccountBase = '/my-account';

export const myAccountLinks = {
  login: `${myAccountBase}/login`,
  customerLogin: `${myAccountBase}/customer-login`,
  partnerLogin: `${myAccountBase}/partner-login`,
  breederLogin: `${myAccountBase}/breeder-login`,
  rehomingLogin: `${myAccountBase}/rehoming-login`,
  vetLogin: `${myAccountBase}/vet-login`,
  customerForgottenDetails: `${myAccountBase}/customer-forgotten-details`,
  partnerForgottenDetails: `${myAccountBase}/partner-forgotten-details`,
  breederForgottenDetails: `${myAccountBase}/breeder-forgotten-details`,
  rehomingForgottenDetails: `${myAccountBase}/rehoming-forgotten-details`,
  vetForgottenDetails: `${myAccountBase}/vet-forgotten-details`,
  resetPassword: `${myAccountBase}/reset-password`,
  setupPassword: `${myAccountBase}/setup-password`,
  dashboard: myAccountBase,
  myPets: `${myAccountBase}/my-pets`,
  myPetsPolicy: `${myAccountBase}/my-pets/policy`,
  accountSettings: `${myAccountBase}/settings`,
  referAFriend: `${myAccountBase}/refer-a-friend`,
  inclusiveBenefits: `${myAccountBase}/inclusive-benefits`,
  freeCover: `${myAccountBase}/free-cover`,
  helpAndContact: `${myAccountBase}/help-and-contact`,
  rewardStatement: `${myAccountBase}/reward-statement`,
  marketingMaterials: `${myAccountBase}/marketing-materials`,
  amendAPolicy: `${myAccountBase}/amend-a-policy`,
  referAFriendTerms: `${myAccountBase}/refer-a-friend-terms`,
  addALitter: `${myAccountBase}/add-litter`,
  claims: `${myAccountBase}/claims`,
  myRewards: `${myAccountBase}/my-rewards`,
  myClaims: `${myAccountBase}/my-claims`,
  vetGuide: `${myAccountBase}/agria-app`,
  breederClubEssentials: `${myAccountBase}/breeder-club-essentials`,
} as const;
