module.exports = [{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"brandId":"539ec2e1-97bd-48c5-970f-5493d885e132","allowedSpecies":["Dog"]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/agria/src/images/icon-512x512.png","icon_options":{"purpose":"any maskable"},"name":"Agria Pet Insurance","short_name":"Agria","start_url":"/","background_color":"#02315B","theme_color":"#02315B","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1864b9fcc160722ec00366f136cbe20f"},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/kc/src/images/icon.svg","icon_options":{"purpose":"any"},"name":"KC Insurance","short_name":"KC","start_url":"/","background_color":"#006954","theme_color":"#006954","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a5df77059bae6772ff0227d58788af5"},
    },{
      plugin: require('../../../themes/my-account/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/brochureware/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"The Kennel Club","contactNumber":"03330 30 10 00","footerTerms":"Agria Pet Insurance Ltd is authorised and regulated by the Financial Conduct Authority, Financial Services Register Number 496160. Agria Pet Insurance Ltd is registered and incorporated in England and Wales with registered number 04258783. Registered office: First Floor, Blue Leanie, Walton Street, Aylesbury, Buckinghamshire, HP21 7QW. Agria insurance policies are underwritten by Agria Forsakring who is authorised and regulated by the Prudential Regulatory Authority and Financial Conduct Authority.","trustpilotUrl":"https://uk.trustpilot.com/review/www.kcinsurance.co.uk","isFreeJourney":true,"petOrAnimal":"pet","affiliateType":"breeder","enableMyAccount":true,"graphqlUrl":"https://agria.uksouth01.umbraco.io/graphql","siteId":1782,"extraInterfaces":[{"interfaceName":"MyAccountGraphTypeInterface","nodeName":"myAccount"}],"subSpecies":false},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
