/* eslint-disable react/no-danger */
import type { GatsbySSR } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GatsbyBrowser, Script, ScriptStrategy } from 'gatsby';
import { BaseLayout } from './src/components/BaseLayout';
import { PageProvider } from './src/context/pageContext';
import { BrochurewareDataLayerWrapper } from './src/components/BrochurewareDataLayerWrapper';

const queryClient = new QueryClient();

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const pageID = props?.data?.page?.remoteId;
  const brand = props?.data?.page?.brand;
  const stickyCallToAction = props?.data?.page?.stickyCallToAction;
  const overrideStickyCTAButton = props?.data?.page?.overrideStickyCTAButton;

  return (
    <BrochurewareDataLayerWrapper
      data={props.data}
      location={props.location}
      pageContext={props.pageContext}
    >
      <PageProvider
        pageID={pageID}
        stickyCallToAction={stickyCallToAction}
        overrideStickyCTAButton={overrideStickyCTAButton}
      >
        <QueryClientProvider client={queryClient}>
          <BaseLayout brand={brand} location={props.location}>
            {element}
          </BaseLayout>
        </QueryClientProvider>
      </PageProvider>
    </BrochurewareDataLayerWrapper>
  );
};

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
);

// export const onRenderBody: GatsbySSR['onRenderBody'] = ({
//   setHeadComponents,
// }) => {
//   setHeadComponents([
//     <Script
//       strategy={ScriptStrategy.postHydrate}
//       type="text/javascript"
//       src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
//       charSet="UTF-8"
//       data-domain-script="0a4dcf85-e4c9-4637-96e9-e56e5771a69d"
//     />,
//   ]);
// };
