import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';
import { ApiResponse, CustomerDetails } from '../types';
import { dataOrError } from '../utils/dataOrError';

const getCustomerDetails = async (customerId: string) => {
  const { data } = await service.get<ApiResponse<CustomerDetails>>(
    `/Customer/GetDetails?customerId=${customerId}`
  );
  return dataOrError<CustomerDetails>(data);
};

export function useGetCustomerDetails() {
  const [customerId, setCustomerId] = useState('');
  const res = useQuery({
    queryKey: ['customerDetails', customerId],
    queryFn: () => getCustomerDetails(customerId),
    enabled: !!customerId
  });

  const fetchCustomerDetails = (newCustomerId: string) => {
    setCustomerId(newCustomerId);
  };

  return {
    ...res,
    fetchCustomerDetails,
  };
}
