export const myAccountConfig = {
  company: 'Kennel Club',
  referenceName: 'a Kennel Club',
  seoTitleSuffix: '| My Account | The Kennel Club',
  partnerIdName: 'Membership Number',
  partnerLoginInfo: 'For breeders',
  breederLoginInfo: 'For Kennel Club Breeder Club members',
  vetLoginInfo: 'For veterinary practices',
  referAFriendUrl: 'https://clk.omgt1.com/?PID=35329&AID=2353495',
  petReferenceName: 'Dog',
  enableMarketingMaterials: false,
} as const;
